
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { lsTest } from '@hokify/shared-components/lib/helpers/localstorage';
import { EventBus } from '@hokify/shared-components/lib/eventbus';

function isHTMLElement(el): el is HTMLElement {
	return el instanceof HTMLElement;
}

@Component({
	name: 'HokVideo'
})
export default class HokVideo extends Vue {
	@Prop({ type: Boolean, required: false, default: false }) readonly ssr!: boolean;

	@Prop({ type: [String, Array], required: true }) readonly source!: string | string[];

	@Prop({ type: String, required: true }) readonly poster!: string;

	@Prop({ type: String, default: '' }) readonly alt!: string;

	@Prop({ type: Boolean, default: true }) readonly muted!: boolean;

	@Prop({ type: Boolean, default: false }) readonly loop!: boolean;

	@Prop({ type: Boolean, default: false }) readonly portraitMode?: boolean;

	@Prop({ type: Boolean, default: false }) readonly hasInteracted!: boolean;

	@Prop({ type: Boolean, default: true }) readonly loadOnInteraction!: boolean;

	@Prop({ type: Object, required: false }) readonly metaData!: any;

	@Prop({ type: Boolean, default: false }) readonly fullWidth?: boolean;

	@Prop({ type: Boolean, default: false }) readonly rounded!: boolean;

	@Prop({
		type: String,
		default: 'auto',
		validator: (value: string) => ['none', 'metadata', 'auto'].includes(value)
	})
	readonly preload!: string;

	@Prop({
		type: String,
		default: 'none',
		validator: (value: string) => ['none', 'full'].includes(value)
	})
	readonly control!: string;

	@Prop({ type: String, required: false, default: 'aspect-video' }) readonly customRatio!: string;

	videoElement!: HTMLVideoElement;

	isPlayable = false;

	isPlaying = false;

	hasStarted = false;

	loadVideo = !this.loadOnInteraction;

	userHasAcceptedExternalCookies = false;

	localStorageAvailable = lsTest();

	handleCanplay() {
		this.isPlayable = true;
	}

	videoPaused() {
		if (this.isPlaying) {
			this.isPlaying = false;
		}
	}

	videoResumed() {
		if (!this.isPlaying) {
			this.isPlaying = true;
		}
	}

	get isYoutubeLink() {
		return (
			typeof this.source === 'string' && this.source.startsWith('https://www.youtube.com/embed/')
		);
	}

	get showControls() {
		// make sure to always show controls on mobile as soon as video is loading (for full control of videos that are without controls on desktop)
		return (this.$isMobile.phone && this.loadVideo) || (this.control === 'full' && this.hasStarted);
	}

	get aspectRatio() {
		let aspectRatio;
		if (this.portraitMode && this.fullWidth) {
			aspectRatio = 'aspect-2/3';
		} else if (this.portraitMode) {
			aspectRatio = 'aspect-9/16';
		} else {
			aspectRatio = this.customRatio;
		}
		return aspectRatio;
	}

	get roundedCorners() {
		if (this.rounded && this.$isMobile.any) {
			return 'rounded-lg';
		}
		if (this.rounded && !this.$isMobile.any) {
			return 'rounded-t-lg';
		}
		return false;
	}

	@Watch('sourceAsArray')
	sourcesChanged() {
		Vue.nextTick(() => {
			this.videoElement.load();
		});
	}

	get sourceAsArray() {
		// if loadVideo is not true (e.g. if loadOninteraction is not true, or on mobile)
		// return empty array (therefore do not preload anything)
		if (!this.loadVideo) {
			return [];
		}

		if (typeof this.source === 'string') {
			return [this.source];
		}
		return this.source;
	}

	fileType(source) {
		return source.split('.').pop();
	}

	get enablePlay() {
		return this.isPlayable && this.loadVideo;
	}

	@Watch('enablePlay') onValueChanged() {
		// if there is no control menu set we want the video to be played automatically as soon as the user has interacted with the page
		// if we are on mobile, enableplay is triggered once button is clicked, so we need to load and play the video afterwards
		if ((this.enablePlay && this.control === 'none') || (this.enablePlay && this.$isMobile.phone)) {
			this.play();
		}
	}

	@Watch('hasInteracted') onInteractionChanged(val: boolean) {
		// set loadvideo to true, but no on mobile (no preload on mobile, start loading only after play click)
		if (val && this.loadOnInteraction && !this.$isMobile.phone) {
			this.loadVideo = true;
		}
	}

	mounted() {
		this.videoElement = this.$refs.videoElement as HTMLVideoElement;
		if (this.isYoutubeLink || this.videoElement.readyState === 4) {
			this.handleCanplay();
		}

		const acceptedCookie = this.localStorageAvailable && localStorage.getItem('accept-cookies');
		if (acceptedCookie && acceptedCookie.startsWith('[')) {
			const parsedCookie = JSON.parse(acceptedCookie);
			this.userHasAcceptedExternalCookies = !!parsedCookie.includes('externalYoutube');
		}

		EventBus().$on('accepted-tracking', parsedCookie => {
			this.userHasAcceptedExternalCookies = !!parsedCookie.includes('externalYoutube');
			if (this.isPlaying && this.hasStarted) {
				if (this.userHasAcceptedExternalCookies) {
					this.play();
				} else {
					this.isPlaying = false;
					this.hasStarted = false;
				}
			}
		});
	}

	get showPlayButton() {
		// only show the playbutton on mobile before user clicked on it for the first time
		// (afterwards we always show the controls form the video component)
		if (this.$isMobile.phone && !this.isYoutubeLink) {
			return !this.isPlaying && !this.hasStarted && !this.loadVideo;
		}
		// on desktop we just always show the button if the video is not playing
		if (this.control === 'full') {
			return !this.isPlaying;
		}
		return false;
	}

	play() {
		if (this.isPlayable) {
			this.isPlaying = true;
			this.hasStarted = true;
			if (this.isYoutubeLink) {
				if (!this.userHasAcceptedExternalCookies || !this.localStorageAvailable) {
					this.openCookieBanner();
				} else {
					this.loadYoutube();
				}
			} else if (this.videoElement.paused) {
				this.videoElement.play();
			}
		} else if (!this.isYoutubeLink) {
			// if we are on mobile the video is not "playable" yet,
			// therefore load the video now
			this.loadVideo = true;
		}
	}

	pause() {
		this.isPlaying = false;
		this.videoElement?.pause();
	}

	loadYoutube() {
		if (isHTMLElement(this.$refs.youtubeVideo) && typeof this.source === 'string') {
			const { parentNode } = this.$refs.youtubeVideo;
			if (!isHTMLElement(parentNode)) return;

			const iframe = document.createElement('iframe');
			iframe.id = 'youtube';
			iframe.classList.add('absolute');
			iframe.classList.add('top-0');
			iframe.classList.add('left-0');
			iframe.classList.add('w-full');
			iframe.classList.add('h-full');
			iframe.setAttribute('frameborder', '0');
			iframe.setAttribute('allowfullscreen', '');
			iframe.width = '100%';
			iframe.src = this.source;
			parentNode.replaceChild(iframe, this.$refs.youtubeVideo);

			if (isHTMLElement(this.$refs.youtubePoster)) {
				this.$refs.youtubePoster.classList.add('invisible');
			}
		}
	}

	openCookieBanner() {
		this.$cookieBanner.toggle(true, { stage: 'config' });
	}

	schemaData() {
		const data = {
			'@context': 'http://schema.org/',
			'@type': 'VideoObject',
			thumbnailUrl: this.poster,
			contentUrl: this.sourceAsArray[0],
			name: this.metaData.name,
			description: this.metaData.description,
			duration: `PT${this.metaData.duration}S`,
			uploadDate: this.metaData.uploadDate
		};

		return JSON.stringify(data);
	}

	beforeDestroy() {
		EventBus().$off('accepted-tracking');
	}
}
